.footer {
  width: 100%;
  background-color: #eaedf1;
  overflow: hidden;
  .footerTitle {
    box-sizing: border-box;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 1.8em;
    padding: 20px;
    width: 100%;
    background-color: #3a464b;
    overflow: hidden;
  }
  .copyright {
    box-sizing: border-box;
    text-align: center;
    color: #555;
    font-size: 12px;
    padding: 20px 0;
  }
}
