@import 'variables';

@media only screen and (max-width: 559px) {
  .dataTables_wrapper .dataTables_filter input {
    margin: 0 0 0 -5px !important;
  }
  .fmh_modal {
    .fmh_modalWrapper {
      margin: 60px auto !important;
      width: 95% !important;
      padding: 10px !important;
      box-sizing: border-box;
    }
  }
}

.fmh_modalWrapper {
  max-width: 90%;
  .box {
    max-width: 100%;
    .fmh_modal_toolbar {
      padding: 5px;
      box-sizing: border-box;
      .fmh_modal_close {
        color: #aaaaaa;
        float: right;
        font-size: 22px;
        font-weight: bold;
      }
      .fmh_modal_close:hover,
      .fmh_modal_close:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
      }
      .fmh_modal_edit,
      .fmh_modal_save,
      .fmh_modal_cancel {
        float: right;
        font-size: 22px;
        font-weight: bold;
        margin: 0 5px 0 0;
      }
      .fmh_modal_edit:hover,
      .fmh_modal_edit:focus,
      .fmh_modal_save:hover,
      .fmh_modal_save:focus,
      .fmh_modal_cancel:hover,
      .fmh_modal_cancel:focus {
        text-decoration: none;
        cursor: pointer;
      }
      .fmh_modal_edit {
        color: $orange;
        &:hover,
        &:focus {
          color: $ltorange;
        }
      }
      .fmh_modal_save {
        color: $dkblue;
        &:hover,
        &:focus {
          color: $ltblue;
        }
      }
      .fmh_modal_cancel {
        color: #aaa;
        &:hover,
        &:focus {
          color: #000;
        }
      }
      .fmh_modal_move {
        float: left;
        margin: 0 5px 0 0;
      }
    }
    .fmh_inner_modal_box {
      padding: 0 10px;
      box-sizing: border-box;
      .header {
        color: $ltblue;
        font-size: 1.6rem;
      }
      .content {
        clear: both;
        width: 100%;
        //border-top: 1px solid #aaa;
        box-sizing: border-box;
        padding: 10px 0 0 0;
        margin: 5px 0 0 0;
        overflow-y: scroll;
      }
    }
    .fmh_modal_actions {
      position: absolute;
      bottom: 0;
    }
  }
}

.fmh_block_page {
    display: none;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}
