/* Color Palette */
$green: #00FF0E;
$ltgreen: #14C71E;
$dkgreen: #1F8A25;
$ltblue: #309CEA;
$dkblue: #147CC7;
$yellow: #fedc2f;
$orange: #e79045;
$ltorange: #ffc600;
$red: #d00000;

/* Fonts */
$raleway: 'Raleway', sans-serif;
