/* Color Palette */
$green: #00FF0E;
$ltgreen: #14C71E;
$dkgreen: #1F8A25;
$ltblue: #309CEA;
$dkblue: #147CC7;
$yellow: #fedc2f;
$red: #d00000;
$ltgray: #eaeaea;

/* Fonts */
$raleway: 'Raleway', sans-serif;

@media print {
  .hWrapper {
    page-break-inside: avoid;
  }
}

@media only screen and (max-width: 559px) {
  .qWrapper50 {
    width: 100% !important;
  }
}

@media only screen and (max-width: 700px) {
  .viewApplications {
    .overview {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
  .group2, .group3, .group4 {
    width: 100% !important;
  }
  .appContact {
    padding: 0 0 10px 0;
    border-bottom: 1px solid #3c3c3c;
  }
  .appEmployment {
    padding: 0 0 10px 0;
  }
  .appEmployment {
    .label {
      width: 100px !important;
    }
  }
  .appChildren {
    margin: 10px 0 0 0;
  }
  .qWrapper-date, .qWrapper-radio, .qWrapper-select, .qWrapper-text {
    width: 100% !important;
  }
}

.appProgressWrapper {
  width: 100%;
  .appProgressBar {
    background-color: #fff;
    height: 3px;
  }
}

.testActions {
  width: 100%;
  clear: both;
  padding: 10px 0 0 10px;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #2e00c7;
  background-image: url("/images/cubes.png");
  .loadTestData {
    float: left;
    margin: 0 10px 10px 0;
  }
  .getFormData {
    float: left;
    margin: 0 10px 10px 0;
  }
  .review {
    float: left;
    margin: 0 10px 10px 0;
  }
  .clearData {
    float: right;
    margin: 0 10px 10px 0;
  }
}

.dataTables_length {
  select {
    width: auto;
    height: auto;
  }
}

.appBody {
  background-color: #fff;
  padding: 10px;
  min-height: 510px;
  //margin-top: 50px;
  .appSection {
    width: 100%;
    max-width: 700px;
    font-size: 16px;
    padding: 0 0 0 10px;
    margin: 0 auto;
    overflow: hidden;
    box-sizing: border-box;
    .sectionTitle {
      background-color: #3b3b3b;
      color: #fff;
      font-family: $raleway;
      padding: 5px 10px;
      margin: 10px 0;
      font-size: 1.25em;
      display: inline-block;
    }
    .questionWrapper {
      padding: 10px 0 10px 0;
      display: table;
      box-sizing: border-box;
      float: left;
      .questionLabel {
        padding: 0 10px 0 6px;
      }
      .questionInput {
        float: left;
        padding: 5px 10px 0 0;
        box-sizing: border-box;
        width: 100%;
        min-width: 265px;
        input[type=text] {
          width: 100% !important;
        }
        label {
          display: block;
          margin: 5px;
          float: left;
          font-family: 'Roboto', sans-serif;
          > input {
            display: none;
          }
          > i {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 5px;
            vertical-align: middle;
            box-shadow: inset 0 0 0 4px #1E88E5;
            transition: .25s;
            &.radio {
              border-radius: 50%;
            }
            &.checkbox {
              border-radius: 30%;
            }
          }
          > span {
            display: inline-block;
            font-size: .9em;
          }
          > input:checked + i {
            background: #1E88E5;
          }
          > input:checked ~ span {
            color: #1E88E5;
            border-bottom: 1px solid #1E88E5;
          }
          &:hover {
            cursor: pointer;
            color: #1E88E5;
          }
        }
        textarea {
          width: 100%;
          height: 3.3rem;
          padding: 5px 8px;
          border: 1px solid #9c9c9c;
          font-size: 1rem;
          font-family: 'Raleway', sans-serif;
        }
      }
      .qInput100 {
        width: 100%;
      }
      .qInput50 {
        width: 50%;
      }
    }
    .qWrapper100 {
      width: 100%;
    }
    .qWrapper50 {
      width: 50%;
    }
  }
}

.summary {
  background-color: #fff;
  padding: 10px;
  min-height: 510px;
  .bkLtgray {
    background-color: $ltgray !important;
  }
  .summaryResponseItem {
    clear: both;
    padding: 5px 0 5px 10px;
    overflow: hidden;
    .qNum {
      float: left;
      padding: 0 10px 0 0;
      font-weight: bold;
      width: 20px;
    }
    .qLabel {
      float: left;
      font-weight: bold;
    }
    .qResponseOneLine {
      float: left;
      padding: 1px 0 0 20px;
    }
    .qResponseCheckAnswer {
      float: left;
      clear: both;
      padding: 3px 0 0 40px;
    }
    .qResponseMultiLine {
      float: left;
      clear: both;
      padding: 3px 0 0 40px;
    }
    .qResponseOneLine, .qResponseCheckbox, .qResponseMultiLine, .qResponseCheckAnswer {
      font-family: monospace;
    }
  }
}

.viewApplications {
  max-width: 850px;
  margin: auto;
  .viewApps_select {
    width: 100%;
    select {
      width: 100%;
      max-width: 500px;
      height: auto;
      max-height: 32px;
    }
  }
  .app_table_wrapper {
    table {
      tbody {
        tr {
          cursor: pointer;
          &:hover {
            background-color: #eee;
          }
          &.selected {
            background-color: #eee;
          }
        }
      }
    }
  }
  .overview {
    width: 100%;
    overflow: auto;
    padding: 10px 10px 0 10px;
    box-sizing: border-box;
    border: 1px solid #3c3c3c;
    margin: 15px 0 0 0;
    .header {
      overflow: auto;
      padding: 0 10px 10px 10px;
      border-bottom: 1px solid #3c3c3c;
      .appFullName {
        font-size: 1.2rem;
        font-family: $raleway;
        font-weight: bold;
        float: left;
      }
      .appDate {
        float: right;
        padding: 4px 0 0 0;
      }
    }
    .summaryContent {
      width: 100%;
      box-sizing: border-box;
      .hWrapper {
        width: 100%;
        box-sizing: border-box;
        clear: both;
        overflow: auto;
        padding: 10px 0;
        //border-bottom: 1px solid #3c3c3c;
        .appContact {
          .label {
            width: 55px;
          }
        }
        .appEmployment {
          .label {
            width: 80px;
          }
        }
        .appChildren {
          .label {
            width: 100px;
          }
        }
        .group1, .group2, .group3, .group4 {
          box-sizing: border-box;
          float: left;
          padding-left: 10px;
        }
        .group1 {
          width: 100%;
        }
        .group2 {
          width: 50%;
        }
        .group3 {
          width: 33.3%;
        }
        .group4 {
          width: 25%;
        }
        .oneLine {
          width: 100%;
          box-sizing: border-box;
          margin: 0 0 2px 0;
          .label {
            font-weight: bold;
            display: inline-block;
          }
          .response {
            display: inline-block;
          }
        }
        .multiLine {
          margin: 0 0 2px 0;
          width: 100%;
          box-sizing: border-box;
          clear: both;
          .label {
            font-weight: bold;
            display: inline-block;
          }
          .response {
            clear: both;
            display: inline-block;
            width: 100%;
            padding: 3px 0 0 10px;
            font-family: monospace;
            box-sizing: border-box;
            //min-height: 40px;
          }
        }
      }
    } //summaryContent
  } //overview
  .viewAppBody {
    background-color: #fff;
    padding: 0 10px;
    min-height: 510px;
    .section {
      width: 100%;
      box-sizing: border-box;
      clear: both;
      overflow: auto;
      padding: 10px;
      page-break-inside: avoid;
      /* border-bottom: 1px solid #3c3c3c; */
      .sectionTitle {
        font-family: "Raleway", sans-serif;
        /* padding: 5px 10px; */
        margin: 0 0 5px 0;
        font-size: .9em;
        display: inline-block;
        width: 100%;
      }
      .sectionResponses {
        padding: 0 10px;
        box-sizing: border-box;
        clear: both;
        font-family: monospace;
        .qWrapper-date, .qWrapper-radio, .qWrapper-select, .qWrapper-text {
          width: 50%;
          float: left;
        }
        .qWrapper-textbox {
          width: 100%;
          float: left;
        }
        .qQuestion-date, .qQuestion-radio, .qQuestion-select, .qQuestion-text, .qQuestion-textbox {
          font-weight: bold;
          float: left;
          padding: 0 10px 0 0;
        }
        .qResponse-date, .qResponse-radio, .qResponse-select, .qResponse-text, .qResponse-textbox {
          float: left;
          font-family: monospace;
        }
      } //sectionResponses
    }
  }
}
