$topBarHeight: 55px;
/* Color Palette */
$green: #00FF0E;
$ltgreen: #14C71E;
$dkgreen: #1F8A25;
$ltblue: #309CEA;
$dkblue: #147CC7;
$yellow: #fedc2f;
$red: #d00000;
$ltgray: #eaeaea;

/* Fonts */
$raleway: 'Raleway', sans-serif;

.topBar {
  position: fixed;
  z-index: 10;
  //overflow: visible;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: $topBarHeight;
  background-color: $ltblue;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  border: 0;
  vertical-align: baseline;
  box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
  .pageTitleBar {
    width: 100%;
    border-bottom: 1px solid #309CEA;
    overflow: hidden;
    .tbIcon {
      font-size: 22px;
      padding: 15px 5px 0 15px;
      float: left;
      cursor: pointer;
      height: 35px;
      width: 30px;
    }
    ul {
      list-style-type: none;
      list-style: none;
      li {
        box-sizing: border-box;
        float: left;
        display: list-item;
        text-align: -webkit-match-parent;
        //height: 55px;
        a {
          display: block;
          font-weight: 500;
          height: 40px;
          line-height: 40px;
          margin: 0;
          padding: 0;
          position: relative;
          color: #fff;
          &:active {
            bottom-border: 2px solid ltblue;
          }
          &:visited {
            color: #fff;
          }
        }
      }
    }
    .pageTitle {
      float: left;
      color: #fff;
      font-size: 26px;
      font-family: "Raleway", sans-serif;
      padding: 10px 0;
    }
    .pageProgressCount {
      float: right;
      color: #fff;
      font-size: 24px;
      font-family: "Raleway", sans-serif;
      padding: 10px 15px 0 0;
    }
  }
  .main_nav {
    float: left;
    li {
      float: left;
    }
  }
  .right_nav {
    float: right;
  }
}

.searchBar {
  width: 100%;
  clear: both;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 10px 10px 10px;
  display: none;
  .searchWrapper {
    background-color: #fff;
    color: #9c9c9c;
    overflow: hidden;
    float: right;
    width: 100%;
    max-width: 400px;
    .searchIcon {
      float: left;
      margin: 5px 0 0 5px;
      font-size: 20px;
    }
    .searchInputWrapper {
      float: left;
      width: 80%;
      border: 0px;
      input {
        border: none;
        outline: none;
      }
    }
    .searchClose {
      float: right;
      margin: 5px 7px 0 0;
      font-size: 20px;
      cursor: pointer;
    }
  }
}

.viewAppActions {
  width: 100%;
  clear: both;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #000;
  background-image: url("/images/cubes.png");
  .actions {
    max-width: none !important;
    .homeTable {
      float: left !important;
      margin-right: 10px;
    }
    .next {
      float: left !important;
      margin-right: 10px;
    }
    .prev {
      float: left !important;
      margin-right: 10px;
    }
    .delete {
      float: right;
    }
  }
}

.genericTools {
  width: 100%;
  clear: both;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #000;
  background-image: url("/images/cubes.png");
  .actions {
    width: 100%;
    max-width: none !important;
    .btn {
      margin: 0 10px 0 0;
    }
    .addNew {
      float: left;
    }
    .delete {
      float: right;
    }
    .search {
      float: right;
      margin-right: 0px !important;
    }
  }
}

/* Dropdown Button */
.tbDropMenu {
  background-color: #3498DB;
  color: white;
  border: none;
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.tbDropMenu:hover, .tbDropMenu:focus {
  background-color: #2980B9;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  position: absolute;
  background-color: #3a464b;
  min-width: 160px;
  box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 55px;
  left: 0px;
  padding: 10px;
  .dropMenuItem {
    padding: 5px;
    &:hover {
      color: #309CEA;
    }
  }
}

.dmStatic {
  border-top: none;
  padding-bottom: 10px !important;
  &:last-of-type{
    border-top: 1px solid !important;
    padding: 10px 5px !important;
  }
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd
}
