@import url('https://fonts.googleapis.com/css?family=Roboto|Raleway:200,400');
@import 'variables';
@import 'application';
@import 'top_bar';
@import 'footer';
@import 'modal';

@media print {
  .no-print, .no-print * {
    display: none !important;
  }
  .printHeader {
    display: block !important;
    max-width: 850px;
    .printTitle {
      float: left;
    }
    .docID {
      float: right;
    }
  }
  .bodyContent {
    padding-top: 0px !important;
  }
}

.clearfix {
  clear: both;
}
.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

.printHeader {
  display: none;
  padding: 0 20px 20px 20px;
}

html {
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
}

h1, h2, h3 {
  font-family: $raleway;
  font-weight: 400;
}

.green {
  background-color: $green;
}
.dkgreen {
  background-color: $dkgreen;
  &:hover {
    background-color: $ltgreen;
  }
}
.ltblue {
  background-color: $ltblue;
}
.dkblue {
  background-color: $dkblue;
  &:hover {
    background-color: $ltblue;
  }
}
.orange {
  background-color: $orange;
  &:hover {
    background-color: $ltorange;
  }
}
.orangeText {
  color: $orange !important;
}
.yellow {
  background-color: $yellow;
}
.yellowText {
  color: $yellow !important;
}
.red {
  background-color: $red;
}
.redText {
  color: $red !important;
}

.leftBar {
  .lbCollapsed {

  }
}

.show {
  display: block;
}
.hide {
  display: none !important;
}
.none {
  display: none !important;
}

.dropdown-content {
  display: none;
}

.initLoading {
  position: relative;
  float: left;
  top: 200px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #3a464a;
  font-size: 100px;
}

.bodyContent {
  padding-top: $topBarHeight;
/**  -webkit-transition: padding-top 1s;
  -moz-transition: padding-top 1s;
  -o-transition: padding-top 1s;
  transition: padding-top 1s;*/
}

.actions {
    display: block;
    clear: both;
    padding: 10px 10px;
    overflow: hidden;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    box-sizing: border-box;
    //background-color: #fff;
}

.btn {
  padding: 10px 20px;
  cursor: pointer;
  color: #fff;
  display: inline-block;
  span {
    width: 20px;
    display: inline-block;
    text-align: center;
    margin: 0 15px 0 0;
  }
}

.topSubmitButton {
  width: 100%;
  .actions {
    padding-top: 0px;
    margin-top: -5px;
  }
}

.fullWidth {
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 10px 0;
}

.disabled {
  background-color: #9e9e9e !important;
  cursor: pointer !important;
}

.prev {
  float: left;
}
.next, .review, .submit {
  float: right;
}

.floatRight {
  float: right !important;
}

/* Form Styles */
input {
  padding: 5px 8px;
  border: 1px solid #9c9c9c;
  font-size: 1.05em;
  width: 100%;
  font-family: 'Raleway', sans-serif;
}

/* Reset Select */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 1;
  box-shadow: none;
  border: 1px solid #9c9c9c;
  background: #fff;
  background-image: none;
}
/* Custom Select */
.select {
  position: relative;
  display: block;
  width: 100%;
  //height: 3em;
  //line-height: 3;
  //padding: 5px 8px;
  //background: #2c3e50;
  overflow: hidden;
  border-radius: .25em;
}
select {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'Raleway', sans-serif;
  color: #000;
  cursor: pointer;
  padding: 5px 8px;
  font-size: 1.05em;
}
select::-ms-expand {
  display: none;
}
/* Arrow */
.select::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: .5em 1em;
  //background: #34495e;
  pointer-events: none;
}
/* Transition */
.select:hover::after {
  color: #3b3b3b;
}
.select::after {
  -webkit-transition: .25s all ease;
  -o-transition: .25s all ease;
  transition: .25s all ease;
}
